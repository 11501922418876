<template lang="pug">
  .wrapper
    .personal__settings(v-if="user.loggedIn")
      v-form(ref="form", v-model="valid", @submit.prevent="submitPersonalData")
        v-row.personal__settings__section
          v-col.personal__settings__avatar__block(cols="3")
            .personal__settings__avatar(@click="avatarDialog = true")
              .preview(v-if="user.avatar_url || newAvatar", :style="{ 'background-image': `url(${avatarUrlWithResize(newAvatar, '240x240')})` }")
              AvatarPlaceholder.md(v-else, :fullName="fullName")
              .personal__settings__avatar__edit(@click="avatarDialog = true") {{ $t('actions.changePicture') }}
          v-col.personal__settings__input__block(cols="6")
            v-layout.personal__settings__user__id(align-center)
              | {{ $t('formFields.userId') }} {{ user.id }}
              UICopyValue.ml-3(:value="user.id")
            v-text-field.personal__settings__input(
              :label="$t('formFields.firstName')",
              v-model.trim="newFirstName",
              :rules="[rules.required, rules.name]",
              @input="onFirstNameInput")
            v-text-field.personal__settings__input(
              :label="$t('formFields.lastName')",
              v-model.trim="newLastName",
              :rules="[rules.required, rules.name]",
              @input="onLastNameInput")
            v-text-field.personal__settings__input(
              :label="$t('formFields.email')",
              v-model.trim="newEmail",
              :rules="[rules.required, rules.email]",
              :value="user.email",
              :disabled="emailChangePending",
              :hide-details="emailChangePending",
              :class="{ filled: emailChangePending }")
            .personal__settings__warning.email(v-if="emailChangePending", @click="cancelEmailChange") {{ $t('actions.cancelEmailChange') }}
            .personal__settings__slug
              v-text-field.personal__settings__input.profile(
                :label="$t('formFields.slug')",
                v-model.trim="newSlug",
                :rules="[rules.required, rules.slug]",
                :error-messages="slugError",
                :disabled="slugDisabled",
                required,
                :class="{filled : slugDisabled}",
                @input="onSlugInput")
              .personal__settings__warning.slug(v-html="$t('messages.warn.slugCanBeChangedOnce')")
            //- v-select.personal__settings__input.double(
            //-   v-model="user.currency_id",
            //-   :items="currencies",
            //-   item-text="code",
            //-   item-value="id",
            //-   :label="$t('formFields.currency')",
            //-   :rules="[rules.required]")
            v-select.personal__settings__input.double.lang(
              :menu-props="{ contentClass: 'select_lang' }",
              v-model="newLanguageId",
              :items="languages",
              item-text="name",
              item-value="id",
              :label="$t('formFields.language')",
              @change="changeLanguage")
            #countries
              v-autocomplete.personal__settings__input(
                v-model="newCountryId",
                :items="countries",
                item-text="name",
                item-value="id",
                :no-data-text="$t('validation.noDataAvailable')",
                :label="$t('hints.countrySelect')",
                :rules="[rules.required]",
                append-icon="mdi-chevron-down",
                :offset-overflow="false",
                attach="#countries",
                autocomplete="off",
                :menu-props="{ allowOverflow: false, offsetOverflow: false, bottom: true, offsetY: true }")
            .personal__settings__success(v-if="updateSuccess") {{ $t('messages.success.personalDataUpdated') }}
            .personal__settings__error(v-if="updateSuccess && emailUpdateSuccess") {{ $t('messages.success.updateEmailSuccess') }}
            .personal__settings__save
              v-btn(:disabled="!valid || loading || submitting || !formChanged", @click="submitPersonalData") {{ $t('actions.save') }}
            .personal__settings__error(v-if="errors.length", v-for="error in errors") #[strong {{ error.title }}] #[span(v-html="error.detail")]
          v-col.personal__settings__help(cols="3")
            .pt-64
            | {{ $t('messages.inform.canChangePersonalData') }}
      v-layout.social__section(column)
        v-row.social(align="center")
          v-col.social__network(cols="3", :class="{ social__network__connected: googleId }") Google
          v-col.social__block(cols="6")
            v-layout.social__info(v-if="googleId")
              v-layout(column, align-start)
                .social__name {{ user.google_full_name }}
                .social__connected {{socialConnectTime(user.google_bound_at, $i18n.locale) }}&nbsp;
                  span.social__connected__disconnect(v-if="canDisconnectGoogle", @click="disconnectSocial('google_id')") {{ $t('actions.disconnect') }}
            v-btn.social__button(v-else, @click="getSocialRedirect('google')", :disabled="!socialBtnActive || !user.is_email_confirmed")
              SvgSocialGoogle(:light="true")
              | {{ $t('actions.connect') }}
            .social__errors(v-if="googleConnectErrors")
              div(v-for="error in googleConnectErrors") #[strong {{ error.title }}:] #[span(v-html="error.detail")]
          v-col.personal__settings__help.social(cols="3") {{ socialConnectText }}
        v-row.social(align="center")
          v-col.social__network(cols="3", :class="{ social__network__connected: fbId }") Facebook
          v-col.social__block(cols="6")
            v-layout.social__info(v-if="fbId")
              v-layout(column, align-start)
                .social__name {{ user.fb_full_name }}
                .social__connected {{ socialConnectTime(user.fb_bound_at, $i18n.locale) }}&nbsp;
                  span.social__connected__disconnect(v-if="canDisconnectFacebook", @click="disconnectSocial('fb_id')") {{ $t('actions.disconnect') }}
            v-btn.social__button(v-else, @click="getSocialRedirect('facebook')", :disabled="!socialBtnActive || !user.is_email_confirmed")
              SvgSocialFacebook(:light="true")
              | {{ $t('actions.connect') }}
            .social__errors(v-if="facebookConnectErrors")
              div(v-for="error in facebookConnectErrors") #[strong {{ error.title }}:] #[span(v-html="error.detail")]
        v-row.social.mt-10(align="center")
          v-col.social__network(cols="3")
          v-col.social__block(cols="6")
            v-btn(@click="deleteDialog = true") {{ $t('formFields.deleteAccount.btn') }}
    v-dialog.dialog(v-model="deleteDialog" fullscreen)
      v-layout.wrapper.delete-account-popup(align-center justify-center column fill-height)
        .justify-center
          .title {{ $t('formFields.deleteAccount.title') }}
        .justify-center.mt-5
          v-text-field(
            :label="$t('formFields.slug')",
            v-model.trim="deleteDialogInput",
            :rules="[rules.required]")
          v-btn(:disabled="deleteDialogInput !== user.slug"
            target="_blank"
            :href="`mailto:support@kickex.com?subject=Delete%20account%20Request&body=${$t('formFields.deleteAccount.mailtoBody', {...user})}`") {{ $t('formFields.deleteAccount.btn') }}
        //pre.text--black {{ decodeURI($t('formFields.deleteAccount.mailtoBody', {...user})) }}
      .picture__close(@click="closeDialog") #[SvgPlus]

    v-dialog.dialog(v-model="avatarDialog", fullscreen)
      v-card.picture.wrapper(@dragenter.stop.prevent="handleDragEnter")
        v-layout.picture__editor(justify-center, align-center)
          v-flex(xl4, lg4, md6, sm12, xs12)
            .picture__title {{ $t('actions.chooseNewPiture') }}
            croppa.picture__cropper(
              v-model="cropper",
              :accept="'.png, .jpg, .jpeg'",
              :placeholder="''",
              :width="300",
              :height="300",
              :quality="avatarQuality",
              :file-size-limit="10000000",
              initial-position="right bottom",
              initial-size="cover",
              show-loading,
              :show-remove-button="false",
              :disable-drag-and-drop="false",
              :show-loading="false",
              :canvas-color="canvasColor",
              :prevent-white-space="true",
              :class="{ selected: fileChosen }",
              @new-image-drawn="onNewImage",
              @file-choose="onFileChoosen",
              @file-size-exceed="fileSizeExceed = true",
              @file-type-mismatch="fileTypeMismatch = true",
              @loading-start="onLoadingStart",
              @loading-end="onLoadingEnd",
              @zoom="onZoom")
              .initial__text(v-if="!fileChosen", @click="cropper.chooseFile()")
                .initial__text__title(v-html="$t('hints.dropPictureOrSelectFile')")
                .initial__text_description {{ $t('validation.fileFormatAndSizeLimitRule', { formats: 'JPEG, PNG', sizeLimit: '10 Mb' }) }}
              .loader(v-if="(cropper && cropper.loading) || loading") {{ $t('messages.inform.loading') }}
            .cropper__error(v-if="fileSizeExceed") {{ $t('validation.fileSizeExceedsTheLimit') }}
            .cropper__error(v-if="fileTypeMismatch") {{ $t('validation.wrongFileFormat') }}
            v-slider.picture__slider(
              v-if="fileChosen",
              type="range",
              v-model="sliderVal",
              :min="sliderMin",
              :max="sliderMax",
              step=".01",
              @input="onSliderChange",
              hide-details,
              color="#19A2DC")
          //- v-flex.picture__help(xl2, lg2, md2, sm12, xs12) {{ $t('hints.imageEditorExplanation') }}
        v-layout(justify-center, align-center)
          v-flex(xl4, lg4, md6, sm12, xs12)
            v-btn.btn(v-if="fileChosen", @click="onSubmitFile", :disabled="loading") {{ $t('actions.save') }}
            span.picture__buttons__cancel(v-if="fileChosen", @click="removeFile") {{ $t('actions.cancel') }}
            .picture__error(v-if="avatarErrors.length", v-for="error in avatarErrors") #[strong {{ error.title }}] #[span(v-html="error.detail")]
          //- v-flex(xl2, lg2, md2, sm12, xs12)
        .picture__close(@click="closeDialog")
          SvgPlus
      .dialog__overlay(
        v-if="dragging",
        draggable,
        @dragleave.stop.prevent="handleDragLeave",
        @dragover.stop.prevent="handleDragOver",
        @drop.stop.prevent="handleDrop")
</template>

<script>
import AvatarPlaceholder from '@/components/AvatarPlaceholder';
import SvgSocialFacebook from '@/components/svg/SvgSocialFacebook';
import SvgSocialGoogle from '@/components/svg/SvgSocialGoogle';
import UICopyValue from '@/components/UICopyValue';
import SvgPlus from '@/components/svg/SvgPlus';
import REGEXP from '@/regexp';
import store from '@/store/store';
import { avatarUrlWithResize, generateId } from '@/utils';
import CONSTANTS from '@/constants';
import { socialConnect } from '@/dates';
import { loadLanguageAsync } from '@/dictionary';

export default {
  components: {
    AvatarPlaceholder,
    SvgSocialFacebook,
    SvgSocialGoogle,
    UICopyValue,
    SvgPlus
  },
  data() {
    return {
      valid: false,
      loading: false,
      submitting: false,
      avatarDialog: false,
      newAvatar: '',
      cropper: null,
      fileChosen: false,
      fileSizeExceed: false,
      fileTypeMismatch: false,
      sliderVal: 0,
      sliderMin: 0,
      sliderMax: 0,
      dragging: false,
      newFirstName: '',
      newLastName: '',
      newSlug: '',
      newEmail: '',
      newCurrency: '',
      newLanguageId: '',
      newCountryId: '',
      emailChangePending: false,
      slug_changes_number: 0,
      fbId: null,
      googleId: null,
      propsChanged: false,
      mimetypes: ['image/png', 'image/jpeg'],
      currencies: [{ id: 1, code: 'USD' }],
      languages: CONSTANTS.LANGUAGES,
      avatarErrors: [],
      slugError: '',
      errors: [],
      updateSuccess: false,
      emailUpdateSuccess: false,
      socialBtnActive: true,
      avatarUrlWithResize,
      originalAvatarWidth: 0,
      originalAvatarHeight: 0,
      deleteDialog: false,
      deleteDialogInput: ''
    };
  },
  methods: {
    socialConnectTime(data, locale) {
      return socialConnect(data, locale);
    },
    changeLanguage(langId) {
      const language = this.languages.find(i => i.id === langId);

      console.log(`%cLanguage: ${language.val.toUpperCase()}`, 'color: #fff; font-weight: bold; background: rgb(230,100,10); padding:3px 5px;');

      loadLanguageAsync(language.val);
      localStorage.setItem('language', language.val);
    },
    onSliderChange(val) {
      this.cropper.scaleRatio = +val;
    },
    onZoom() {
      this.sliderVal = this.cropper.scaleRatio;
    },
    onNewImage() {
      this.originalAvatarWidth = this.cropper.naturalWidth;
      this.originalAvatarHeight = this.cropper.naturalHeight;

      this.sliderVal = this.cropper.scaleRatio.toFixed(2, 10);
      this.sliderMin = this.cropper.scaleRatio.toFixed(2, 10);
      this.sliderMax = (this.cropper.scaleRatio * 5).toFixed(2, 10);
    },
    onLastNameInput(val) {
      this.newLastName = val.charAt(0).toUpperCase() + val.slice(1);
      this.propsChanged = false;
    },
    onFirstNameInput(val) {
      this.newFirstName = val.charAt(0).toUpperCase() + val.slice(1);
      this.propsChanged = false;
    },
    onSlugInput(val) {
      this.propsChanged = false;
      this.slugError = '';
      this.newSlug = val.toLowerCase();

      if (val.includes('kickuser') && this.newSlug !== this.user.slug) {
        this.slugError = this.$t('validation.slugContainsReservedWord', { word: 'kickuser' });
      }
    },
    closeDialog() {
      this.avatarDialog = false;
      if (this.cropper) this.cropper?.remove();
      this.fileChosen = false;
      this.deleteDialog = false;
    },
    handleDragEnter(e) {
      this.dragging = true;
      this.cropper._handleDragEnter.apply(this, [e]);
    },
    handleDragLeave(e) {
      this.dragging = false;
      this.cropper._handleDragLeave.apply(this, [e]);
    },
    handleDragOver(e) {
      this.cropper._handleDragOver.apply(this, [e]);
    },
    handleDrop(e) {
      this.cropper._handleDrop.apply(this, [e]);
      this.dragging = false;
    },
    onLoadingStart() {
      this.loading = true;
    },
    onLoadingEnd() {
      this.loading = false;
    },
    onFileChoosen(file) {
      this.fileSizeExceed = false;
      this.fileTypeMismatch = false;

      if (file.size > 10000000 || !this.mimetypes.includes(file.type)) {
        this.removeFile();
      } else {
        this.fileChosen = true;
      }
    },
    removeFile() {
      this.cropper.remove();
      this.fileChosen = false;
    },
    onSubmitFile() {
      const avatar = this.generateDataUrl();
      const payload = {
        data: {
          type: 'user-profile-internal',
          attributes: { avatar }
        }
      };

      this.loading = true;

      this.$store.dispatch('user/updateProfile', payload)
        .then(() => {
          this.avatarDialog = false;
          this.removeFile();
          this.fileChosen = false;
          if (!this.$store.state.user.loadingProfile) this.$store.dispatch('user/getUser');
        })
        .catch(errors => {
          if (errors.message) {
            this.avatarErrors = [{ title: this.$t('messages.error.undefined'), detail: errors.message }];
          } else {
            this.avatarErrors = errors;
          }
        })
        .finally(() => { this.loading = false; });
    },
    generateDataUrl() {
      if (this.cropper.chosenFile.type === 'image/png') {
        return this.cropper.generateDataUrl('image/png', 0.8).split(',')[1];
      }

      return this.cropper.generateDataUrl('image/jpeg', 0.8).split(',')[1];
    },
    disconnectSocial(socialNameAttr) {
      this.loading = true;

      const payload = {
        data: {
          type: 'user-profile-internal',
          attributes: {
            [socialNameAttr]: ''
          }
        }
      };

      this.updateProfile(payload);
    },
    cancelEmailChange() {
      const payload = {
        data: {
          type: 'user-profile-internal',
          attributes: {
            is_email_change_pending: false
          }
        }
      };

      this.updateProfile(payload);
    },
    async submitPersonalData() {
      if (this.submitting) return;

      if (this.$refs.form.validate()) {
        this.loading = true;
        this.submitting = true;

        const payload = {
          data: {
            type: 'user-profile-internal',
            attributes: {
              first_name: this.newFirstName,
              last_name: this.newLastName
            }
          }
        };

        if (this.newSlug !== this.user.slug) {
          payload.data.attributes.slug = this.newSlug;
        }

        if (this.newEmail !== this.user.email) {
          payload.data.attributes.email = this.newEmail;
          this.emailUpdateSuccess = true;
        }

        if (this.newLanguageId !== this.user.language_id) {
          payload.data.attributes.language_id = this.newLanguageId;
        }

        if (Number(this.newCountryId) !== this.user.registration_country_id) {
          payload.data.attributes.registration_country_id = +this.newCountryId;
        }

        await this.updateProfile(payload);

        setTimeout(() => { this.submitting = false; }, 500);
      }
    },
    updateProfile(payload) {
      this.errors = [];
      this.updateSuccess = false;
      this.$store.dispatch('user/updateProfile', payload)
        .then(() => {
          this.updateSuccess = true;

          if (!this.propsChanged) this.propsChanged = true;

          this.$store.dispatch('user/refreshToken')
            .then(() => {
              if (!this.$store.state.user.loadingProfile) {
                this.$store.dispatch('user/getUser')
                  .finally(() => {
                    this.loading = false;
                  });
              }
            })
            .catch(() => { this.loading = false; });
        })
        .catch(errors => {
          this.errors = errors;
          this.loading = false;
        });
    },
    getSocialRedirect(socialName) {
      this.socialBtnActive = false;

      this.$store.dispatch('user/refreshToken').then(() => {
        this.$store.dispatch('user/getSocialRedirectUrls', { socialName, type: 'connect', router: this.$router })
          .then(redirectUrl => {
            const state = generateId();

            localStorage.setItem('oauth_state', state);
            localStorage.setItem('access_token_for_social_connect', localStorage.getItem('access_token'));
            window.location = `${redirectUrl}&state=${state}`;
          })
          .catch(errors => {
            if (errors.message) {
              this.errors = [{ title: 'Oops, something went wrong', detail: errors.message }];
            } else {
              this.errors = errors;
            }
          })
          .finally(() => { this.socialBtnActive = true; });
      });
    }
  },
  computed: {
    user() { return this.$store.state.user; },
    countries() { return this.$store.state.user.countries; },
    canvasColor() { return this.loading ? '#f4f4f5' : 'white'; },
    socialConnectText() {
      if (this.user.is_email_confirmed) return this.$i18n.t('CTAs.connectSocialMedia');

      return this.$i18n.t('messages.warn.connectSocialDisabled');
    },
    slugDisabled() {
      return this.slug_changes_number > 0;
    },
    fullName() {
      if (this.propsChanged) return `${this.newFirstName.charAt(0)} ${this.newLastName.charAt(0)}`;

      return `${this.user.first_name.charAt(0)} ${this.user.last_name.charAt(0)}`;
    },
    rules() {
      return {
        email: v => REGEXP.email.test(v.trim()) || this.$t('validation.emailInvalid'),
        required: v => !!v || this.$t('validation.required'),
        name: v => REGEXP.name.test(v) || this.$t('validation.nameRules', { min: 1, max: 160 }),
        slug: v => (v.length >= 1 && v.length <= 20) || this.$t('validation.slugRules', { min: 1, max: 20 })
      };
    },
    formChanged() {
      return this.user.slug !== this.newSlug
        || this.user.last_name !== this.newLastName
        || this.user.first_name !== this.newFirstName
        || this.user.email !== this.newEmail
        || this.user.registration_country_id !== Number(this.newCountryId)
        || this.user.language_id !== this.newLanguageId;
    },
    avatarQuality() {
      if (!this.originalAvatarWidth && !this.originalAvatarHeight) return 2;

      const scale = Math.ceil(Math.max(this.originalAvatarWidth, this.originalAvatarHeight) / 300);

      return scale <= 2 ? 2 : scale;
    },
    canDisconnectGoogle() {
      return this.user.is_password_set || this.fbId;
    },
    canDisconnectFacebook() {
      return this.user.is_password_set || this.googleId;
    },
    socialConnectErrors() {
      return this.$store.state.user.socialConnectErrors;
    },
    facebookConnectErrors() {
      if (!this.socialConnectErrors) return [];

      return this.socialConnectErrors.filter(err => err.socialName === 'facebook');
    },
    googleConnectErrors() {
      if (!this.socialConnectErrors) return [];

      return this.socialConnectErrors.filter(err => err.socialName === 'google');
    },
    kycSessionErrors() {
      return this.$store.state.user.kycSessionErrors;
    }
  },
  watch: {
    user: {
      handler(val) {
        if (val.loggedIn && !this.loading) {
          this.fbId = val.fb_id;
          this.googleId = val.google_id;
          this.newAvatar = val.avatar_url;
          this.newFirstName = val.first_name;
          this.newLastName = val.last_name;
          this.newSlug = val.slug;
          this.newEmail = val.email;
          this.newCurrency = val.currency_id;
          this.newLanguageId = val.language_id;
          this.emailChangePending = val.is_email_change_pending;
          this.slug_changes_number = val.slug_changes_number;
          this.newCountryId = val.registration_country_id ? String(val.registration_country_id) : null;
        }
      },
      deep: true
    },
    kycSessionErrors(val) {
      if (val && val.length && val.some(err => err.code === 47)) {
        this.$refs.form.validate();
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (store.getters['user/loggedIn']) {
        vm.googleId = store.state.user.google_id;
        vm.fbId = store.state.user.fb_id;
        vm.newAvatar = store.state.user.avatar_url;
        vm.newFirstName = store.state.user.first_name;
        vm.newLastName = store.state.user.last_name;
        vm.newSlug = store.state.user.slug;
        vm.newEmail = store.state.user.email;
        vm.newCurrency = store.state.user.currency_id;
        vm.newLanguageId = store.state.user.language_id;
        vm.emailChangePending = store.state.user.is_email_change_pending;
        vm.slug_changes_number = store.state.user.slug_changes_number;
        vm.newCountryId = store.state.user.registration_country_id ? String(store.state.user.registration_country_id) : null;
      }
    });
  },
  async mounted() {
    if ((this.kycSessionErrors && this.kycSessionErrors.length)
    || !this.user.registration_country_id || !this.user.last_name || !this.user.first_name) {
      this.$refs.form.validate();
    }

    if (!this.countries.length) {
      await this.$store.dispatch('user/getAllCountries');
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  .personal__settings {
    margin-top: 60px;

    &__avatar {
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: center;
      transition: opacity .3s ease-in-out;

      .preview {
        height: 120px;
        border-radius: 60px;
        width: 120px;
        min-width: 120px;
        z-index: 2;
        background-color: $white;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 20px;

        &:hover {
          background-color: $grey-text;
          opacity: .8;
        }
      }

      &__edit {
        color: $blue;
        cursor: pointer;
        font-size: 12px;
        font-weight: 600;
        margin-top: 10px;
      }
    }

    &__user__id {
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 30px;
    }

    &__warning {
      color: $light-theme-text;
      font-size: 12px;
      text-align: left;

      &.email {
        margin-bottom: 30px;
        cursor: pointer;
        color: $blue;
      }

      &.slug {
        padding-left: 16px;
        @media screen and (max-width: 450px) { padding: 0 0 6px; }
      }

      ::v-deep span { color: $error-color; }
    }

    &__slug {
      display: flex;
      justify-content: flex-start;
      @media screen and (max-width: 450px) { flex-direction: column-reverse; }
    }

    &__input {
      width: auto !important;

      &.filled ::v-deep .v-input__slot { background-color: $light-theme-line !important; }

      &.profile {
        width: 100% !important;
        max-width: 336px;

        @media screen and (max-width: 450px) { max-width: 100%; }
      }

      &.double {
        display: inline-block;
        max-width: 180px;

        @media screen and (max-width: 392px) {
          display: block !important;
          max-width: 100% !important;
          margin: 15px auto 0 0 !important;
        }

        &.lang { max-width: 140px; }
      }

      &__block { text-align: left; }
    }

    &__help {
      color: $grey-text;
      font-size: 12px;
      text-align: left;

      .pt-64 {
        @media (min-width: 1025px) {
          padding-top: 64px;
        }
      }
    }

    &__error {
      color: $error-color;
      line-height: 1;
      margin: 20px 0;

      &.avatar {
        width: 120px;
        font-size: 12px;
      }
    }

    &__success {
      margin-top: 15px;
      margin-bottom: 15px;
      font-weight: 600;
    }

    #countries { position: relative; }
  }

  .social {
    @media screen and (max-width: 980px) { flex-wrap: wrap; }
    &__section { margin-top: 60px; }

    &__network {
      color: $light-theme-text;
      font-size: 16px;
      font-weight: 600;
      text-align: left;

      &__connected { color: $sub-black; }

      @media screen and (max-width: 980px) {
        order: 2;
        text-align: left;
      }
    }

    &__block {
      text-align: left;
      @media screen and (max-width: 980px) { order: 3; }
    }

    &__avatar {
      background-color: $white;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 33px;
      height: 66px;
      margin-right: 22px;
      width: 66px;
      min-width: 66px;
    }

    &__name {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }

    &__connected {
      color: $light-theme-text;
      font-size: 12px;
      font-weight: 600;

      &__disconnect {
        color: $blue;
        margin-left: 15px;
        text-decoration: none;
        cursor: pointer;
      }
    }

    &__button {
      width: 230px;

      svg {
        height: 22px;
        margin-right: 5px;
      }
    }

    &__other {
      margin: 20px 0;
      @media screen and (max-width: 980px) { flex-wrap: wrap; }

      &__input {
        margin: 0 !important;
        width: 468px !important;
        ::v-deep input { margin: auto 0; }
        @media screen and (max-width: 980px) { width: auto !important; }
        * { ::v-deep input { margin: auto 0; } }
      }
    }

    &__youtube__icon,
    &__discord__icon {
      height: 24px;
      width: 24px;
    }

    &__errors {
      color: $error-color;
      font-size: 14px;
      margin-top: 15px;
    }
  }

  .picture {
    text-align: left;
    min-height: 100vh;

    &.wrapper { padding: 0 20px !important; }

    &__close {
      position: absolute;
      top: 45px;
      right: 45px;
      width: 24px;
      height: 24px;
      min-width: 24px;
      transform: rotate(45deg);
      opacity: .54;

      svg { cursor: pointer; }

      &__progress {
        height: 6px;
        width: 100px;
        background-color: $blue;
      }
    }

    &__title {
      margin: 160px 0 30px;
      font-weight: bold;
      font-size: 35px;
      line-height: 40px;
      color: $sub-black;
    }

    &__cropper {
      border: 2px dashed $light-theme-22-text;
      width: 304px;
      height: 304px;
      border-radius: 50%;
      background-color: $white;
      margin-bottom: 20px;
      z-index: 10;

      &.selected { border: 0; }

      &:hover { border-color: $blue-lighter; }

      ::v-deep canvas {
        border-radius: 50%;

        @media screen and (max-width: 980px) {
          width: 100% !important;
          height: 100% !important;
        }
      }

      .btn { margin: 0 !important; }
    }

    &__slider { max-width: 304px; }

    &__preview img {
      width: 100%;
      border-radius: 5px;
    }

    &__help {
      font-size: 12px;
      color: $grey-text;
    }

    &__buttons {
      &__cancel {
        cursor: pointer;
        color: $error-color;
        font-weight: 600;
        margin-left: 40px;
      }
    }

    .initial__text {
      font-size: 12px;
      color: $black;
      position: absolute;
      width: 250px;
      height: 46px;
      top: calc(50% - 23px);
      left: calc(50% - 125px);
      z-index: 1;
      display: flex;
      align-items: center;
      flex-direction: column;

      &__title {
        font-size: 16px;
        text-align: center;
        color: $sub-black;
        font-weight: 600;
        margin-bottom: 6px;
        ::v-deep span { color: $blue; }
      }

      &_description {
        font-size: 12px;
        text-align: center;
        color: $sub-black;
        opacity: 0.38;
      }
    }

    .loader {
      position: absolute;
      width: 100px;
      top: 50%;
      left: 50%;
      margin-left: -50px;
      text-align: center;
      color: $grey-text;
      font-size: 12px;
      font-weight: 600;
    }

    &__error { color: $error-color; }
  }

  .dialog {
    position: relative;

    &__overlay {
      z-index: 10000;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $drop-overlay-background;
    }
  }

  .cropper__error {
    color: $error-color;
    font-weight: 600;
  }

  ::v-deep .v-dialog {
    border-radius: 10px !important;
    overflow-y: visible;

    .countries_modal {
      border-radius: 10px !important;
      padding: 32px 35px 42px 39px;
      text-align: left;

      h3 {
        font-size: 24px;
        margin-bottom: 35px;
      }

      #countries_input { position: relative; }

      .v-btn { width: 100%; }

    }

    .delete-account-popup {
      background-color: #fff !important;

      .v-input {
        width: 350px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .personal__settings {
      margin-top: 50px;

      &__section {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &__help,
      &__input__block {
        min-width: 600px;
      }

      &__avatar { order: 1; }
      &__help:not(.social) { order: 2; }
      &__input__block { order: 3; }

      &__help.social {
        order: 1;
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
      }

      .social__section {
        width: 100%;
        max-width: 610px;
        margin: 60px auto 0;
      }

      .social__network { order: 2; }
      .social__block { order: 3; }

      .row.social { margin: 0; }
    }
  }

  @media screen and (max-width: 600px) {
    .personal__settings__help,
    .personal__settings__input__block,
    .personal__settings__avatar__block {
      max-width: 100%;
      min-width: unset;
    }

    .row.social {
      flex-direction: column;

      .social__network,
      .social__block {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }
</style>
