<template lang="pug">
  .ui-copy(@click="onClick")
    svg(width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(fill-rule="evenodd" clip-rule="evenodd" d="M12 0H2C0.847713 0 -1.90735e-06 0.847716 -1.90735e-06 2V12C-1.90735e-06 13.1523 0.847713 14 2 14H6V18C6 19.1523 6.84771 20 8 20H18C19.1523 20 20 19.1523 20 18V13L19 13V14.5H18V18H8V14H12C13.1523 14 14 13.1523 14 12V8H18V13H19L19 11.5H20V10H19V8.5H20V8C20 7.62907 19.9122 7.28969 19.7546 7H19V6.24542C18.7103 6.08785 18.3709 6 18 6H14V2C14 0.847716 13.1523 0 12 0ZM12 2V12H2V2H12Z" fill="#19A2DC")
    transition(name="fade")
      .ui-copy__text(v-if="isCopied") {{ $t('messages.inform.copiedToClipboard') }}
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      required: true
    }
  },

  data() {
    return {
      isCopied: false
    };
  },

  methods: {
    onClick() {
      this.isCopied = true;
      navigator.clipboard.writeText(this.value);

      setTimeout(() => {
        this.isCopied = false;
      }, 2000);
    }
  }
};
</script>

<style scoped lang="scss">
.ui-copy {
  display: flex;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;

  &__text {
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    border-radius: 3px;
    background-color: #000;
    color: #fff;
    white-space: nowrap;
    font-size: 12px;
    padding: 6px 10px;
    z-index: 1;
  }

  .fade-enter-active, .fade-leave-active {
    transition: all .15s ease-in-out;
    opacity: 1;
    transform: translate3d(0,0,0);
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
    transform: translate3d(0,-10px,0);
  }
}
</style>
